<template>
  <div class="box">
    <div class="left">
      <table>
        <tr v-for="(item, index) in leftList" :key="index">
          <td style="text-align: left; width: 10%;">{{ index + 1 }}</td>
          <td style="text-align: left; width: 15%;">
            <img width="20%" src="../assets/left2.png">
            3
            <img width="20%" src="../assets/right2.png">
          </td>
          <td style="text-align: left;">{{ item.store_user_name }}</td>
          <td style="text-align: right;">{{ formatNumber(item.point) }}</td>
        </tr>
      </table>
    </div>
    <div class="center">
      <div calss="top">
        <carousel-3d :autoplay="true" :autoplayTimeout="3000" :perspective="35" :display="5" :animationSpeed="1000" :width="300" :height="200" >
      <slide v-for="(item, i) in slides" :index="i" :key="i">
        <img :src="item.src" />
      </slide>
    </carousel-3d>
      </div>
      <div class="month">
        <table>
          <tr v-for="(item, index) in centerList" :key="index">
            <td style="text-align: left; width: 10%;">{{ index + 1 }}</td>
            <td style="text-align: left; width: 15%;">
              <img width="20%" src="../assets/left2.png">
              {{3}}
              <img width="20%" src="../assets/right2.png">
            </td>
            <td style="text-align: left;">{{ item.store_user_name }}</td>
            <td style="text-align: right;">{{ formatNumber(item.point) }}</td>
          </tr>
        </table>
      </div>


    </div>
    <div class="right">
      <table>
        <tr v-for="(item, index) in rightList" :key="index">
          <td style="text-align: left; width: 10%;">{{ index + 1 }}</td>
          <td style="text-align: left; width: 15%;">
            <img width="20%" src="../assets/left2.png">
            {{ 3 }}
            <img width="20%" src="../assets/right2.png">
          </td>
          <td style="text-align: left;">{{ item.store_user_name }}</td>
          <td style="text-align: right;">{{ formatNumber(item.point) }}</td>
        </tr>
      </table>
    </div>

  </div>
</template>

<script>
/* eslint-disable */
export default {

  name: 'rank',
  data() {
    return {
      leftList: [
      ],
      rightList: [
        // 更多列表项
      ],
      centerList: [
        // 更多列表项
      ],
      slides: [
        { src: require('@/assets/backgame2.png')},
        { src: require('@/assets/backgame2.png')},
        { src: require('@/assets/backgame2.png')},

      ]
    }
  },
  computed: {

  },
  mounted() {
    let that = this
      that.$axios({
        method: "get",//指定请求方式
        url: "/api/get_log_tops?store_id=3&type=1" ,//请求接口
      }).then(function (res) {
        that.leftList=res.data.data;
      })
      that.$axios({
        method: "get",//指定请求方式
        url: "/api/get_log_tops?store_id=3&type=2" ,//请求接口
      }).then(function (res) {
        that.rightList=res.data.data;
      })
      that.$axios({
        method: "get",//指定请求方式
        url: "/api/get_log_tops?store_id=3&type=3" ,//请求接口
      }).then(function (res) {
        that.centerList=res.data.data;
      })
      
  },
  beforeDestroy() {
  },
  methods: {
    formatNumber(value) {
      return value.toLocaleString();
    }
  }
}
</script>

<style scoped>
.carousel-3d-slide{
  border: 0px;
  background-color: transparent;
}
.top .top{
  background-image: url("@/assets/backgame2.png");
}
td {
  color: white;
  font-family: 'SourceHanSansCN';
  padding: 10px;
  /* 增加内边距 */
  text-align: center;
  /* 水平居中对齐 */
  vertical-align: middle;
  /* 垂直居中对齐 */
  border-bottom: 1px solid #66f2ff;
  line-height: 100%;
}

table {
  width: 100%;
  /* 表格宽度占满容器 */
  table-layout: fixed;
  /* 固定布局 */
  border-collapse: collapse;
  /* 边框合并 */
}

.box {
  background-image: url("../assets/rank.jpg");
  background-size: 100% auto;
  /* 宽度完全展开，高度自动调整 */
  background-repeat: no-repeat;
  background-position: center;
  /* 确保图片居中显示 */
  background-color: black;
  /* 设置背景颜色为黑色 */
  width: 100%;
  height: 100vh;
  margin-top: 0px;
  display: flex;
}

.left {
  width: 21.3%;
  height: 65%;
  margin-top: 10%;
  margin-left: 8%;
  overflow: hidden;
}

.center {
  width: 25.5%;
  height: 58%;
  margin-top: 17.5%;
  margin-left: 8%;
  overflow: hidden;

}

.right {
  width: 21.3%;
  height: 65%;
  margin-top: 10%;
  margin-left: 8%;
  overflow: hidden;

}

.top {
  height: 50%;
}

.month {
  margin-top: 12%;
  height: 100%;
}
</style>