<template>
  <div class="box">
    <div class="all">
      <div class="top_left">
        <div style="font-family:MaokenAssortedSans;letter-spacing: 4px;">{{ name }}</div>
        <div style="font-size: 60px;line-height: 72px;letter-spacing: 6px;">第{{ level }}关</div>
      </div>
      <div class="center">
        <div class="center_left">
          <div class="in">
            <div class="intime"> {{ time }}</div>
            <img class="inimg" src="../assets/djs.png" alt="">
          </div>
          <div>
            <canvas ref="canvas" width="570" height="570" class="progress-bar"></canvas>
            <div class="countdown"></div>
          </div>
        </div>
        <div class="center_right">
          <img class="mbdc" src="../assets/mbdc.png" alt="">
          <div class="center_right_text">
            <div class="center_right_text_in">{{ title }}</div>
          </div>
          <div class="center_right_bottom">
            <div class="center_right_bottom_left">
              <div style="line-height: 76px;">
                <CountTo :startVal='startVal' :endVal='score' :duration='duration' :autoplay="false" ref="countTo"
                  style="font-weight: bold;letter-spacing: 4px;" />
              </div>
              <div style="font-size: 36px;line-height: 93px;font-weight: bold;">
                关卡分数
              </div>
            </div>
            <div class="center_right_bottom_right">
              <div style="line-height: 76px;">
                {{ countdown }}
              </div>
              <div style="font-size: 36px;line-height: 93px;font-weight: bold;">
                剩余目标数
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="bottom">
        <div class="line" v-for="index in life" :key="index">
          <img :src="require(`../assets/xin${index}.png`)" alt="">
          <!-- <img :src="require(`../assets/xin.png`)" alt="" style="width: 101px;"> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountTo from 'vue-count-to'
/* eslint-disable */
export default {
  components: {
    CountTo
  },
  name: 'PaGe2',
  data() {
    return {
      angle: 50,
      startVal: 0,
      duration: 1600,
      countdown: 10,
      mount: 10,
      oldtime: 100,
      time: 100,
      life: 5,
      score: 0,
      percentage: 1
    }
  },
  computed: {
    progress() {
      return (this.countdown / this.mount) * 100;
    },
    progresstime() {
      return (this.time / this.oldtime) * 100;
    },
  },
  mounted() {
    this.drawPie();
    window.getMessage = this.getMessage;//获取推送数据
    if (this.$route.query.str) {
      this.getMessage(this.$route.query.str)
      setTimeout(this.valChange, 1000)
    };
  },
  beforeDestroy() {
    // 组件销毁时清除计时器
    clearInterval(this.timer);
  },
  methods: {
    drawPie() {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext('2d');
      const x = canvas.width / 2;
      const y = canvas.height / 2;
      const radius = 285;
      const startAngle = 0;
      const endAngle = 2 * Math.PI * this.percentage;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.arc(x, y, radius, startAngle, endAngle);
      ctx.closePath();
      ctx.fillStyle = 'rgb(35, 255, 0)';
      ctx.fill();
    },
    valChange() {
      this.$refs.countTo.start();
    },
    timer() {
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.time--;
          this.percentage = this.time / this.oldtime;
          this.drawPie();
        }
        if (this.time <= 0) {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    getMessage(str) {
      console.log(str)
      const strs = str.split('-')
      //开始倒计时 游戏开始开始倒计时
      if (strs[0] == 'start') {
        this.time = strs[1];
        this.oldtime = strs[1];
        this.mount = strs[2];
        this.countdown = strs[2];
        let that = this;
        that.timer();
        this.score = Number(localStorage.getItem('score'));
        this.startVal = Number(localStorage.getItem('score'));
      }
      //游戏扣减生命值
      if (strs[0] == 'life') {
        this.life = Number(strs[1]);
      }
      //游戏目标节点数，要换算成环形百分比
      if (strs[0] == 'node') {
        this.countdown = strs[1];
      }
      if (strs[0] == 'score') {
        this.score = strs[1];
      }
      //游戏结束，跳转结束页面 获取得分
      if (strs[0] == 'end') {
        // this.startVal = this.score
        // this.score = Number(strs[1]);
        if (this.life > 0&&this.time>0) {//成功页面page6
          // setTimeout(this.valChange, 1)
          this.$router.push({ path: '/page6', query: { startVal:this.score,score: Number(strs[1])} })

        } else {//失败页面page7
          localStorage.setItem('score', 0);
          // this.$router.push({ path: '/page6', query: { score: this.score } })
          this.$router.push({ path: '/page7', query: { score: this.score } })
        }
      }
      //跳转
      if (strs[0] == 'jump') {
        this.$router.push({ path: '/page3' })
      }
      if (strs[0] == 'title') {
        this.name = strs[1]
        this.level = strs[2]
        this.times = strs[3]
        this.title = strs[4]
      }
    }
  }
}
</script>

<style scoped>
.contain {
  position: relative;
  width: 200px;
  height: 200px;
}

.main {
  height: 100%;
  background: black;
  border-radius: 100px;
}

.common {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
}

.mask2 {
  transform: rotate(45deg);
  transform-origin: left center;
  left: 100px;
  border-radius: 0 100px 100px 0;
  background: lightgreen;
}

.box {
  background-image: url("../assets/back.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
}

.top_left {
  background-color: aqua;
  width: 250px;
  height: 123px;
  font-size: 30px;
  margin-left: 87px;
  padding-top: 37px;
  border-radius: 0px 0px 30px 30px;
  text-align: center;
  font-weight: bold;
}

.center {
  display: flex;
  margin-top: 35px;
}

.center_left {
  width: 380px;
  height: 380px;
  margin-left: 230px;
  margin-top: 48px;
  position: relative;
}

.in {
  width: 440px;
  height: 400px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-28%, -28%);
  color: #fff;
  text-align: center;
  font-size: 138px;
  font-family: 'bth';
  line-height: 40px;
  padding-top: 40px;
  z-index: 99;
  background: black;
}

.inimg {
  margin-top: 67px;
  width: 150px;
}

.intime {
  font-size: 166px;
  margin-top: 126px
}

.progress-bar {
  transform: rotateX(180deg) rotate(90deg);
}

.center_right {
  margin-left: 240px;
  margin-top: 22px;
}

.mbdc {
  margin-left: 6px;
  height: 68px;
  margin-bottom: 24px;
}

.center_right_text {
  width: 830px;
  height: 205px;
  padding: 5px;
  border-radius: 30px;
  background: -webkit-linear-gradient(45deg, #05f43b, #0be7C1, #00dcdf);
  -moz-linear-gradient: (45deg, #05f43b, #0be7C1, #00dcdf);
  -ms-linear-gradient: (45deg, #05f43b, #0be7C1, #00dcdf);
}

.center_right_text_in {
  width: 770px;
  height: 165px;
  border-radius: 30px;
  background-color: #000;
  font-family: SourceHanSansCN, SourceHanSansCN;
  padding: 20px 30px;
  font-size: 100px;
  color: #fff;
  text-align: center;
  letter-spacing: 10px;
  font-weight: bold;
}

.center_right_bottom {
  display: flex;
  margin-top: 40px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-size: 90px;
  font-weight: bold;
}

.center_right_bottom_left {
  width: 400px;
  height: 189px;
  background: #00FFFC;
  border-radius: 20px 20px 20px 20px;
  padding-top: 56px;
  margin-right: 37px;
  text-align: center;
}

.center_right_bottom_right {
  width: 400px;
  height: 189px;
  background: #05F43B;
  border-radius: 20px 20px 20px 20px;
  padding-top: 56px;
  margin-right: 37px;
  text-align: center;
}

.bottom {
  background-image: url("../assets/smz.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 796px;
  padding-left: 663px;
  height: 127px;
  display: flex;
  margin: 41px auto;
}

.line {
  margin-top: 23px;
  margin-right: 40px;
  margin-left: 12px;
}

.canvas {
}
</style>
