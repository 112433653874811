<template>
  <div class="all">
    <div class="right">
      <div class="right_top">
        120
      </div>
      <div class="right_bottom">
        SECONDS
      </div>
    </div>
    <div class="left">
      <div class="left_card" v-for="item, index in list" :key="index">
        <div class="card_dian" :style="'background:' + 'rgb(' + item.color + ')'"></div>
        <div class="card_name">{{ item.name }}</div>
        <div class="card_num">
          <CountTo :startVal='startVal' :endVal='Number(item.num)' :duration='duration' :autoplay="false"
            :ref="'countTo' + index" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import CountTo from 'vue-count-to'
export default {
  name: 'PaGe2',
  components: {
    CountTo
  },
  mounted() {
    setTimeout(this.valChange, 1000)
  },
  data() {
    return {
      startVal: 0,
      duration: 1500,
      list: [
        { name: '玩家一', num: '60', color: '255,0,0' },
        { name: '玩家2', num: '60', color: '26,235,38' },
        { name: '玩家3', num: '60', color: '6,1,255' },
        { name: '玩家4', num: '60', color: '255,228,5' },
        { name: '玩家5', num: '60', color: '140,0,255' },
      ]
    }
  },
  methods: {
    valChange() {
      this.list.forEach((item, index) => {
        this.$refs['countTo' + index][0].start();
      })
    },
  }
}
</script>

<style scoped>
.all {
  width: 100%;
  height: 100vh;
  font-family: 'bth';
  /* background-image: url("../assets/back.png"); */
  background: black;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
}

.right {
  width: 50%;
  text-align: center;
}

.right_top {
  font-size: 396px;
  font-weight: 400;
  color: #FFFFFF;
  margin-top: 300px;
  line-height: 300px;
}

.right_bottom {
  font-size: 123px;
  font-weight: 400;
  color: #FFFFFF;
}

.left {
  width: 50%;
  margin-top: 147px;
}

.left_card {
  display: flex;
  color: #FFFFFF;
  margin-bottom: 55px;
}

.card_dian {
  width: 95px;
  height: 95px;
  border: 7px solid #ffff;
  border-radius: 50%;
}

.card_name {
  width: 382px;
  height: 108px;
  line-height: 118px;
  font-size: 78px;
  color: #FFFFFF;
  margin-left: 68px;
}

.card_num {
  width: 51px;
  height: 45px;
  font-size: 78px;
  line-height: 118px;
  color: #FFFFFF;
}
</style>
