<template>
  <div class="all">
    <div class="right">
      <div class="right_top">
        <div class="line"
             v-for="index in 5"
             :key="index"></div>
      </div>
      <div class="right_bottom">
        <div class="num">30</div>
        <div class="tittle">SECONDS</div>
      </div>
    </div>
    <div class="left">
      <div class="left_top">
        <div class="in"
             @click="changetime()">
          {{ countdown }}
        </div>
        <div>
          <div class="progress-bar"
               :style="`--progress: ${progress}%`"></div>
          <div class="countdown"></div>
        </div>
      </div>
      <div class="left_bottom">
        <div class="left_bottom_title">关卡分数</div>
        <div class="left_bottom_num">200</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PaGe2',
  data() {
    return {
      countdown: 8,
    }
  },
  computed: {
    progress() {
      return (this.countdown / 8) * 100;
    },
  },
  methods: {
    changetime() {
      if (this.countdown > 1) {
        this.countdown--;
      }
    }
  }
}
</script>

<style scoped>
.all {
  width: 100%;
  height: 100vh;
  font-family: "pmzd";
  background-image: url("../assets/back.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
}

.right_top {
  display: flex;
  padding-top: 50px;
}

.line {
  width: 98px;
  height: 231px;
  background: rgb(35, 255, 0);
  margin-right: 58px;
}

.right_bottom {
  width: 893px;
  height: 667px;
  border: 12px solid #FFFFFF;
  border-radius: 70px;
  margin-top: 67px;
}

.num {
  color: #FFFFFF;
  text-align: center;
  font-size: 437px;
  font-family: 'bth';
  height: 467px;
  line-height: 467px;
}

.tittle {
  color: #FFFFFF;
  text-align: center;
  font-family: 'bth';
  letter-spacing: 15px;
  font-size: 136px;
}

.left_top {
  width: 522px;
  height: 522px;
  border: 23px solid #707070;
  border-radius: 50%;
  margin-left: 195px;
  margin-top: 42px;
  position: relative;
}

.in {
  width: 302px;
  height: 302px;
  border: 14px solid #707070;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  font-size: 194px;
  line-height: 302px;
  font-family: 'bth';
  z-index: 99;
  background: black;
}


.progress-bar {
  width: 522px;
  height: 522px;
  border-radius: 50%;
  background: conic-gradient(rgb(35, 255, 0) var(--progress), transparent var(--progress));
  transform: scaleX(-1);
}

.countdown {
  text-align: center;
  font-size: 24px;
}

.left_bottom {
  width: 643px;
  height: 340px;
  border: 12px solid #FFFFFF;
  border-radius: 70px;
  margin-left: 150px;
  margin-top: 70px;
  font-family: 'bth';
}

.left_bottom_title {
  text-align: center;
  color: #FFFFFF;
  font-size: 97px;
  margin-top: 35px;
}

.left_bottom_num {
  text-align: center;
  color: #FFFFFF;
  font-size: 126px;
}
</style>
