<template>
  <div class="all">
    <div class="top_all">
      <div class="right">
        <div class="right_top">
          <div class="line"
               v-for="index in 5"
               :key="index"></div>
        </div>
        <div class="right_bottom">
          <div class="num">30</div>
          <div class="tittle">SECONDS</div>
        </div>
      </div>
      <div class="left">
        <div class="left_bottom">
          <div class="left_bottom_title">关卡分数</div>
          <div class="left_bottom_num">200</div>
        </div>
      </div>
    </div>
    <div class="bottomline"></div>
    <div class="allround">
      <div class="round"
           v-for="index in 8"
           :key="index">

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PaGe2',
  data() {
    return {

    }
  }
}
</script>

<style scoped>
.all {
  width: 100%;
  background-image: url("../assets/back.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100vh;
  font-family: "pmzd";
}

.top_all {
  display: flex;
  justify-content: center;
}

.right_top {
  display: flex;
  padding-top: 50px;
}

.line {
  width: 98px;
  height: 231px;
  background: rgb(35, 255, 0);
  margin-right: 58px;
}

.right_bottom {
  width: 917px;
  height: 487px;
  border: 12px solid #FFFFFF;
  border-radius: 70px;
  margin-top: 50px;
}

.num {
  color: #FFFFFF;
  text-align: center;
  font-size: 388px;
  font-family: 'bth';
  height: 338px;
  line-height: 338px;
}

.tittle {
  color: #FFFFFF;
  text-align: center;
  font-family: 'bth';
  letter-spacing: 15px;
  font-size: 136px;
  line-height: 100px;
}



.left_bottom {
  width: 643px;
  height: 340px;
  border: 12px solid #FFFFFF;
  border-radius: 70px;
  margin-left: 150px;
  margin-top: 70px;
  font-family: 'bth';
  margin-top: 421px;
}

.left_bottom_title {
  text-align: center;
  color: #FFFFFF;
  font-size: 97px;
  line-height: 200px;
}

.left_bottom_num {
  text-align: center;
  color: #FFFFFF;
  font-size: 126px;
  line-height: 100px;
}

.bottomline {
  width: 100%;
  height: 4px;
  background: #fff;
  margin-top: 59px;
}

.allround {
  display: flex;
  justify-content: space-between;
  width: 1616px;
  margin: 0 auto;
}

.round {
  width: 95px;
  height: 95px;
  border: 4px solid #FFFFFF;
  border-radius: 50%;
  margin-top: 46px;
}
</style>
