<template>
  <div class="box">
    <div class="all">
      <div class="top">
        <div class="top_left"></div>
        <div class="top_right">{{ time }}</div>
      </div>
      <div class="center">
        <div class="center_left">
          <div style="font-size: 200px;margin-top: 45px;width: 100%; text-align: center;font-weight: bold;">{{ countdown
            }}</div>
          <div style="font-size: 51px;">剩余目标数</div>
        </div>
        <div class="center_right">
          <div class="smzall">
            <div class="smz"></div>
            <div class="line" v-for="index in life" :key="index">
              <img :src="require(`../assets/smz${index}.png`)" alt="">
            </div>
          </div>
          <div class="left_bottom">
            <div class="left_bottom_title">关卡分数</div>
            <div class="left_bottom_num">
              <CountTo :startVal='startVal' :endVal='score' :duration='duration' :autoplay="false" ref="countTo"/>
            </div>
          </div>
        </div>
      </div>
      <div class="mbdc">
        {{ title }}
      </div>
      <div class="bottom">
        <p class="bottom_title" style="margin-left: 181px;">{{level}}</p>
        <p class="bottom_title" style="font-family: MaokenAssortedSans;">{{ name }}</p>
        <p class="bottom_title" style="margin-right: 184px;">{{times}}</p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import CountTo from 'vue-count-to'
export default {
  name: 'PaGe2',
  components: {
    CountTo
  },
  data() {
    return {
      startVal: 0,
      duration: 3500,
      countdown: 0,
      mount: 10,
      time: 113,
      life: 5,
      score: 0
    }
  },
  computed: {
    progress() {
      return (this.countdown / this.mount) * 100;
    },
  },
  mounted() {
    window.getMessage = this.getMessage;//获取推送数据
    // if (this.$route.query.str) {
    //   this.getMessage(this.$route.query.str)
      
    // };
  },
  beforeDestroy() {
    // 组件销毁时清除计时器
    clearInterval(this.timer);
  },
  methods: {
    valChange() {
      this.$refs.countTo.start();
    },
    timer() {
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.time--;
        }
        if (this.time <= 0) {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    getMessage(str) {
      const strs = str.split('-')
      //开始倒计时 游戏开始开始倒计时
      if (strs[0] == 'start') {
        this.time = strs[1];
        this.mount = strs[2];
        this.countdown = strs[2];
        let that = this;
        that.timer();
      }
      //游戏扣减生命值
      if (strs[0] == 'life') {
        this.life = Number(strs[1]);
      }
      //游戏目标节点数，要换算成环形百分比
      if (strs[0] == 'node') {
        this.countdown = strs[1];
      }
      console.log(strs)
      if (strs[0] == 'score') {
        console.log(strs[1])
        this.score = Number(strs[1]);
      }
      if (strs[0] == 'success') {
        this.score = Number(strs[1]);
      }
      //游戏结束，跳转结束页面 获取得分
      if (strs[0] == 'end') {
        this.startVal=this.score
        this.score = Number(strs[1]);
        if (this.life > 0 ) {//成功页面page6
          setTimeout(this.valChange, 1)
        } else {//失败页面page7
          // this.$router.push({ path: '/page6', query: { score: this.score } })
          this.$router.push({ path: '/page7', query: { score: this.score } })
        }
      }
      //跳转
      if (strs[0] == 'jump') {
        this.$router.push({ path: '/page3' })
      }
      if (strs[0] == 'title') {
        this.name=strs[1]
        this.level=strs[2]
        this.times=strs[3]
        this.title=strs[4]
      }
      
    }
  }
}
</script>

<style scoped>
.box {
  background-image: url("../assets/back.png");
  background-color: #000;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.all {
  font-family: "SourceHanSansCN";
}

.top {
  width: 940px;
  margin-top: 114px;
  display: flex;
}

.top_left {
  width: 373px;
  height: 194px;
  background-image: url("../assets/djs.png");
  background-size: 100% 100%;
  margin-left: 14px;
}

.top_right {
  width: 484px;
  height: 196px;
  font-family: "HanWangZonYi";
  font-style: oblique;
  font-size: 292px;
  color: #FFFFFF;
  line-height: 206px;
  text-align: center;
  letter-spacing: 20px;
  text-transform: none;
}

.center {
  width: 940px;
  margin-top: 56px;
  display: flex;
}

.center_left {
  width: 414px;
  height: 330px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  color: #00040A;
  line-height: 92px;
  font-style: normal;
  text-transform: none;
  background: #05F43B;
  border-radius: 50px 50px 50px 50px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.center_right {
  margin-left: 39px;
}

.smzall {
  display: flex;
  margin-left: 8px
}

.smz {
  width: 246px;
  height: 49px;
  background-image: url("../assets/smz.png");
  background-size: 100% 100%;
}

.left_bottom {
  width: 490px;
  height: 239px;
  border: 5px solid #FFFFFF;
  border-radius: 50px;
  margin-top: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left_bottom_title {
  color: #FFFFFF;
  font-size: 38px;
  margin-top: 25px;
  margin-left: 73px;
  font-weight: bold;
  text-align: center;
  height: 69px;
}

.left_bottom_num {
  color: #FFFFFF;
  margin-right: 76px;
  text-align: center;
  font-weight: bold;
  font-size: 72px;
}

.mbdc {
  width: 940px;
  height: 77px;
  background: #00FFFC;
  border-radius: 39px 39px 39px 39px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  font-size: 38px;
  color: #000000;
  line-height: 77px;
  text-align: center;
  font-style: normal;
  text-transform: none;
  margin-top: 40px;
}

.bottom {
  width: 944px;
  height: 133px;
  margin-top: 40px;
  box-sizing: border-box;
  border-radius: 50px;
  background-image: url("../assets/boder.png");
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
  font-size: 48px;
  color: #FFFFFF;
  line-height: 133px;
}

.bottom_title {
  background: -webkit-linear-gradient(45deg, #05f43b, #0be7C1, #00dcdf);
  -moz-linear-gradient: (45deg, #05f43b, #0be7C1, #00dcdf);
  -ms-linear-gradient: (45deg, #05f43b, #0be7C1, #00dcdf);
  color: transparent;
  -webkit-background-clip: text;
  animation: ran 10s linear infinite;
  font-weight: bold;
  /*背景颜色以文本方式剪切*/
}
</style>
