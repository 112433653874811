<template>
  <div class="main">
    <div class="all">
    <div class="left">
      <div class="left_top">
        <img src="../assets/left2.png" @click="left()" alt="">
        <div v-if="!mod" class="top_tittle">团队模式</div>
        <div v-if="mod" class="top_tittle">计时模式{{ time }}</div>
        <img src="../assets/right2.png" @click="right()" alt="">
      </div>
      <div class="left_bottom">
        <div class="bottom_title">
        </div>
        <div class="bottom_allcard">
          <div :class="active === index ? 'bottom_card_active' : 'bottom_card'" v-for="item, index in gameList"
            @click="changegame(index)" :key="index">
            {{ item.name }}
          </div>
        </div>
        <div class="gameall">
          <div class="bottom_input" type="text">{{ title }}
          </div>
          <div class="game" @click="open()">游戏说明</div>
        </div>
        <div class="bottom_level">
          LEVEL
        </div>
        <div class="bottom_num">
          <div :class="numactive === index ? 'num_active' : 'num'" v-for="index in 10" @click="changenum(index)"
            :key="index">
            <span v-if="index <= levleLock">{{ index }}</span>
            <img v-else style="width: 60%;" src="../assets/锁.png">
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="right_top">
        <div class="right_top_title">
          <div class="title">
            Player Name
          </div>
          <div class="title">Time Left
          </div>
        </div>
        <div class="right_top_card_all" id="users">
          <div class="right_top_card" v-for="(item, index) in list" :key="index">
            <div :class="item.name ? 'right_top_card_right_hover' : 'right_top_card_right'">
              {{ item.name }}
            </div>
            <div :class="item.time ? 'right_top_card_left_hover' : 'right_top_card_left'" class="">
              {{ item.time }}
            </div>
          </div>
        </div>
      </div>
      <div class="reset" @click="reset">
        RESET
      </div>
      <div class="start" @click="start">
      </div>
    </div>
    <div v-if="show" class="modal">
      <div class="modal-content">
        <img  src="../assets/gif/test.gif" style="width: 100%;">
        <span class="close" @click="show = false">
          <img src="../assets/close.png" @click="open()" alt=""></span>
        <p></p>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'PaGe',
  data() {
    return {
      show: false,
      devid: 'dakx6nrdrlp4260cvmdqbxew1lg12jvh',
      active: 0,
      numactive: 1,
      ganeindex: 0,
      gameList: [],
      levleLock: 4,//默认锁关
      title: "",
      list: [],
      users: [],
      time: -1,//计时剩余时间
      mod: null,//模式
      timer: null,
      maxPeople:5,
      minPeople:2
    }
  },
  mounted() {
    window.cardMessage = this.cardMessage;//用户进入
    window.over = this.over;//游戏结束
    this.gameInfo();
  },
  beforeMount() {
    this.width = "屏幕宽度：" + window.screen.width
    this.height = "，屏幕高度：" + window.screen.height

  },
  methods: {
    open() {
      this.show = !this.show
    },
    gameInfo() {
      this.devid = this.$route.query.devid
      // this.devid = this.devIds[this.ganeindex];
      //获取设备信息
      let that = this
      that.$axios({
        method: "get",//指定请求方式
        url: "/api/get_equipment?imei=" + this.devid,//请求接口
        headers: { 'token': sessionStorage.getItem('token') }
      }).then(function (res) {
        that.configure = res.data.config
        that.gameList = res.data.gameList
        that.title = that.gameList[0].remark
        that.maxPeople = res.data.maxPeople
        that.minPeople = res.data.minPeople
        if (that.configure.time) {
          that.mod = "time";
        }
      })
    },
    right() {
      if (this.ganeindex < this.devIds.length - 1) {
        this.ganeindex++
      }
      this.gameInfo();
    },
    left() {
      if (this.ganeindex > 0) {
        this.ganeindex--
      }
      this.gameInfo();
    },
    changegame(i) {
      this.active = i
      this.title = this.gameList[i].remark
      // this.getLock();
    },

    changenum(i) {
      if (i <= this.levleLock) {
        this.numactive = i
      }
    },
    doTimer() {
      this.timer = setInterval(() => {
        if (this.time > 0) {
          this.time--;
        }
        if (this.time <= 0) {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    start() {
      //倒计时模式
      if (this.time <= 0 && this.configure.time) {
        this.time = this.configure.time * 60//分钟转换秒
        this.doTimer();
      }
      if (this.users.length < this.minPeople) {
        this.$message({ message: '至少'+this.minPeople+'人开启游戏', type: 'error' });
        return false;
      }
      let game_name = this.gameList[this.active].name;
      let level = this.numactive.toString();
      let configure = this.configure
      let num = this.users.length;
      let game_configure = JSON.parse(this.gameList[this.active].config);
      let game_id = game_configure.id;

      document.location = `toapp://startGame?time=${this.time}&level=${level}&game_configure=${this.gameList[this.active].config}&game=${game_id}&num=${num}&configure=${configure}`
      // this.log({ "game_id": game_id, "game_name": game_name, "level": level, "game_status": "start", "is_finish": 0 });

    },//游戏结束	是否通过[0 1] over("1-1-1000")
    over(str) {
      const strs = str.split('-')
      let is_finish = strs[0];
      let level = strs[1];
      let point = strs[2];
      let game_id = this.gameList[this.active].game_id;
      let game_name = this.gameList[this.active].game_name;
      //如果过关同步积分
      if (is_finish) {
        this.point({ "game_id": game_id, "game_name": game_name, "level": level, point: point });
      }
      this.log({ "game_id": game_id, "game_name": game_name, "level": level, "game_status": "end", "is_finish": is_finish });
      this.reset();
      if(is_finish=='0'){
        document.location = `toapp://over`;
      }
    },//游戏日志
    log(data) {
      let that = this
      this.users.forEach(element => {
        that.$axios({
          method: "post",//指定请求方式
          url: "/api/set_game_log",//请求接口
          headers: { 'token': element.token },
          data: data
        })
      });
    },
    point(data) {
      let that = this
      this.users.forEach(element => {
        that.$axios({
          method: "post",//指定请求方式
          url: "/api/set_point_log",//请求接口
          headers: { 'token': element.token },
          data: data
        })
      });
    },
    getLock() {
      let that = this
      this.users.forEach(element => {
        that.$axios({
          method: "get",//指定请求方式
          url: "/api/get_last_game_log?is_finish=1&game_id=" + that.gameList[that.active].game_id,//请求接口
          headers: { 'token': element.token }
        }).then(function (res) {
          console.log(res.data);
          if (res.data.data.length == 0) {
            that.levleLock = 4;
          } else {
            that.levleLock = res.data.data;
          }

        })
      });

    },
    getUser(userId) {
      let that = this
      that.$axios({
        method: "get",//指定请求方式
        url: "/api/get_player/"+userId,//请求接口
      }).then(function (res) {
        let user = res.data;
        // user.token = token;
        let time = user.leftTime;
        if (time <= 0) {
          that.$message({ message: '游戏时间不足', type: 'error' });
          return false;
        }
        that.users.push(user);


        that.$message({ message: '玩家加入' + user.name, type: 'success' });
        that.list.push({ name: user.name, time: Math.floor(time / 3600) + ":" + Math.floor(time / 60) % 60 + ":" + Math.floor(time % 60) });
        document.location = `toapp://join?num=${that.list.length}`
        // that.getLock();//拉取锁
      })
    },
    reset() {
      this.active = 0
      this.numactive = 1
      this.ganeindex = 0
      this.list = [];
      this.users = [];

    },

    //读卡器读用户
    cardMessage(msg) {
     
      let that = this
      msg = msg.substring(msg.length - 10, msg.length - 2);
      msg = msg.substring(6, 8) + msg.substring(4, 6) + msg.substring(2, 4) + msg.substring(0, 2);
      msg = parseInt(msg, 16)
      let repeat = false;
      this.users.forEach(element => {
        if (element.cardNumber == msg) {
          repeat = true
          return;
        }
      });
      if (repeat) {
        return false;
      }
      that.$axios({
        method: "post",//指定请求方式
        url: "/api/appLogin",//请求接口
        data: {
          principal: msg,
          appType: 7
        }
      }).then(function (res) {
        let name = res.data.nickName;
        let token = res.data.access_token;
        //刷卡登录
        if (that.users.length >= that.maxPeople) {
          that.$message({ message: '最多'+that.maxPeople+'人', type: 'error' });
          return false;
        }
        if (token) {
          that.getUser(res.data.userId);
         
        } else {
          that.$message({ message: '非法玩家', type: 'error' });
        }
        // console.log(res);
      }).catch(function(error){
        that.$message({ message: error.response.data, type: 'error' });
      })
    }
  }
}


</script>

<style scoped>

.main {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-image: url("../assets/background2.png");
  /* background-size: 100% 100%; */
  background-size: cover; /* 覆盖整个元素 */
  background-position: center; /* 图片居中显示 */
  background-repeat: no-repeat; /* 不重复图片 */

}
.all{
  display: flex;
  font-family: "pmzd";
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}
.left {
  width: 43.5%;
  text-align: center;
}

.left_top {
  display: flex;
  justify-content: center;
  margin-top: 93px;
  width: 845px;
}

.left_top img {
  width: 73px;
  height: 103px;
}

.top_tittle {
  width: 311px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95px;
  margin-top: 4px;
  font-size: 39px;
  font-family: MaokenAssortedSans;
  background-image: url("../assets/ms.png");
  background-size: 100% 100%;
  color: #fff;
  border-radius: 10px;
  letter-spacing: 0px;
  margin-right: 20px;
  margin-left: 20px;
}

.left_bottom {
  width: 758px;
  height: 680px;
  margin-top: 27px;
  background-image: url("../assets/leftbackground.png");
  background-size: 100% 100%;
  padding: 57px 39px 60px 48px;
  background-repeat: no-repeat;
}

.bottom_title {
  width: 105px;
  height: 30px;
  margin: 0 auto;
  font-family: SourceHanSansCN, SourceHanSansCN;
  background-image: url("../assets/game.png");
  background-size: 100% 100%;
  font-size: 36px;
  color: #fff;
}

.bottom_allcard {
  height: 231px;
  overflow: auto;
  margin-top: 17px;
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bottom_allcard::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.bottom_card {
  width: 318px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid #AFFCFF;
  border-radius: 10px;
  margin-bottom: 20px;
  font-size: 24px;
  background: rgba(3, 124, 171, 0.4);
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: MaokenAssortedSans;
  color: #fff;
}

.bottom_card_active {
  font-family: MaokenAssortedSans;
  background: rgba(0, 169, 250, 1);
  width: 320px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #AFFCFF;
  border-radius: 10px;
  margin-bottom: 20px;
  font-size: 24px;
  color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(175, 252, 255, 1);
  position: relative;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.gameall {
  position: relative;
  display: flex;
  width: 720px;
  height: 118px;
  background: rgba(2, 27, 60, 0.6);
  border-radius: 10px 10px 10px 10px;
  margin-left: 18px;
  margin-top: 15px;
}

.bottom_input {
  border: none;
  color: #fff;
  resize: none;
  width: 450px;
  font-size: 18px;
  margin: 48px;
  margin-left: 38px;
  letter-spacing: 2px;
  text-align: left;
  font-family: SourceHanSansCN, SourceHanSansCN;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bottom_input::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.game {
  width: 138px;
  height: 47px;
  background: rgba(0, 169, 250, 0.2);
  border-radius: 10px 10px 10px 10px;
  border: 4px solid #00A9FA;
  font-size: 18px;
  display: flex;
  align-items: center;
  font-weight: bold;
  justify-content: center;
  letter-spacing: 3px;
  color: #fff;
  font-family: SourceHanSansCN, SourceHanSansCN;
  margin-top: 30px;
}

.bottom_level {
  font-size: 36px;
  color: #ffffff;
  margin-top: 24px;
  letter-spacing: 3px;
  font-family: SourceHanSansCN, SourceHanSansCN;
}

.bottom_num {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 42px;
  margin-left: 20px;
}

.num {
  width: 66px;
  height: 66px;
  border: 4px solid #affcff;
  color: #fff;
  font-size: 26px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-right: 31px;
  font-size: 24px;
  justify-content: center;
  /* font-family: "hyt"; */
  margin-top: 35px;
  font-weight: bold;
  font-family: SourceHanSansCN, SourceHanSansCN;
}

.num_active {

  background: rgba(0, 169, 250, 1);
  box-shadow: 0px 0px 20px 0px rgba(175, 252, 255, 1);
  width: 66px;
  height: 66px;
  border: 4px solid #affcff;
  color: #fff;
  font-size: 24px;
  border-radius: 10px;
  margin-right: 31px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
  /* font-family: "hyt"; */
  font-family: SourceHanSansCN, SourceHanSansCN;
}

.right {
  text-align: center;
}

.right_top {
  width: 806px;
  height: 457px;
  border-radius: 10px;
  margin-top: 101px;
  margin-left: 111px;
  background-image: url("../assets/backgame2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-bottom: 5px;
}

.right_top_title {
  display: flex;
  font-size: 37px;
  margin-left: 17px;
  margin: 0 auto;
  width: 702px;
  margin-bottom: 30px;
}

.right_top_title .title {
  margin-top: 50px;
  width: 50%;
  color: #FFFFFF;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  font-family: SourceHanSansCN, SourceHanSansCN;

}

.right_top_card_all {
  height: 327px;
  overflow-y: auto;
  font-family: SourceHanSansCN, SourceHanSansCN;
}

.right_top_card_all::-webkit-scrollbar {

  width: 0px;

  height: 0px;

}

.right_top_card {
  display: flex;
  justify-content: space-between;
  margin-left: 17px;
  padding: 0 50px;
}

.right_top_card_right {
  width: 327px;
  height: 68px;
  border-radius: 10px 10px 10px 10px;
  border: 2px solid #AFFCFF;
  margin-right: 30px;
  border-radius: 10px;
  line-height: 70px;
  margin-bottom: 11px;
  font-size: 29px;
  font-weight: bold;
  color: #fff;
}

.right_top_card_left {
  width: 327px;
  height: 68px;
  border-radius: 10px 10px 10px 10px;
  border: 2px solid #AFFCFF;
  margin-right: 17px;
  border-radius: 10px;
  line-height: 70px;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 24px;
  color: #fff;
}

.right_top_card_right_hover {
  width: 327px;
  height: 68px;
  background: #00A9FA;
  border-radius: 10px 10px 10px 10px;
  border: 2px solid #AFFCFF;
  margin-right: 30px;
  border-radius: 10px;
  font-weight: bold;
  line-height: 70px;
  margin-bottom: 10px;
  font-size: 24px;
  color: #fff;
}

.right_top_card_left_hover {
  width: 327px;
  height: 68px;
  background: #00A9FA;
  border-radius: 10px 10px 10px 10px;
  border: 2px solid #AFFCFF;
  margin-right: 17px;
  border-radius: 10px;
  font-weight: bold;
  line-height: 70px;
  margin-bottom: 10px;
  font-size: 24px;
  color: #fff;
}

.reset {
  width: 234px;
  height: 64px;
  background: #F05275;
  border-radius: 10px 10px 10px 10px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  font-size: 36px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 78px 0px 39px 396px;
  letter-spacing: 3px;
  font-family: SourceHanSansCN, SourceHanSansCN;
}

.start {
  width: 714px;
  height: 199px;
  background-image: url("../assets/start2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  font-size: 85px;
  color: #ffffff;
  line-height: 173px;
  letter-spacing: 10px;
  margin: 80px 0px 0px 158px;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  background-image: url("../assets/leftbackgrounddig.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: calc(50vh - 387px) auto;
  padding: 20px;
  width: 1318px;
  height: 734px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
