<template>
  <div class="box">
  <div class="all">
    <div class="right">
      <div class="right_top">
        <div class="line"
             v-for="index in life"
             :key="index"></div>
      </div>
      <div class="right_bottom">
        <div class="num"> {{ time }}</div>
        <div class="tittle">SECONDS</div>
      </div>
    </div>
    <div class="left">
      <div class="left_top">
        <div class="in">
          {{ countdown }}
        </div>
        <div>
          <div class="progress-bar"
               :style="`--progress: ${progress}%`"></div>
          <div class="countdown"></div>
        </div>
      </div>
      <div class="left_bottom">
        <div class="left_bottom_title">关卡分数</div>
        <div class="left_bottom_num">{{ score }}</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'PaGe2',
  data() {
    return {
      countdown: 10,
      mount: 10,
      time: 0,
      life: 5,
      score: 0
    }
  },
  computed: {
    progress() {
      return (this.countdown / this.mount) * 100;
    },
  },
  mounted() {
    window.getMessage = this.getMessage;//获取推送数据
    if (this.$route.query.str) {
      this.getMessage(this.$route.query.str)
    };
  },
  beforeDestroy() {
    // 组件销毁时清除计时器
    clearInterval(this.timer);
  },
  methods: {
    timer() {
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.time--;
        }
        if (this.time <= 0) {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    getMessage(str) {
      console.log(str)
      const strs = str.split('-')
      //开始倒计时 游戏开始开始倒计时
      if (strs[0] == 'start') {
        this.time = strs[1];
        this.mount = strs[2];
        this.countdown = strs[2];
        let that = this;
        that.timer();
      }
      //游戏扣减生命值
      if (strs[0] == 'life') {
        this.life = Number(strs[1]);
      }
      //游戏目标节点数，要换算成环形百分比
      if (strs[0] == 'node') {
        this.countdown = strs[1];
      }
      if (strs[0] == 'score') {
        this.score = strs[1];
      }
      //游戏结束，跳转结束页面 获取得分
      if (strs[0] == 'end') {
        this.score = strs[1];
        if (this.life > 0 && this.countdown == 0) {//成功页面page6
          this.$router.push({ path: '/page6', query: { score: this.score } })
        } else {//失败页面page7
          this.$router.push({ path: '/page7', query: { score: this.score } })
        }
      }
      //跳转
      if (strs[0] == 'jump') {
        this.$router.push({ path: '/page3'})
      }
    }
  }
}
</script>

<style scoped>
.box{
  background-image: url("../assets/back.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
}
.all {
  width: 100%;
  height: 100vh;
  font-family: "pmzd";
 
  display: flex;
  justify-content: center;
}

.right_top {
  display: flex;
  padding-top: 50px;
}

.line {
  width: 70px;
  height: 164px;
  background: rgb(35, 255, 0);
  margin-right: 42px;
}

.right_bottom {
  width: 628px;
  height: 450px;
  border: 8px solid #FFFFFF;
  border-radius: 70px;
  margin-top: 48px;
}

.num {
  color: #FFFFFF;
  text-align: center;
  font-size: 311px;
  font-family: 'bth';
  height: 311px;
  line-height: 311px;
}

.tittle {
  color: #FFFFFF;
  text-align: center;
  font-family: 'bth';
  letter-spacing: 10px;
  font-size: 97px;
}

.left_top {
  width: 380px;
  height: 380px;
  border: 17px solid #707070;
  border-radius: 50%;
  margin-left: 139px;
  margin-top: 30px;
  position: relative;
}

.in {
  width: 215px;
  height: 215px;
  border: 10px solid #707070;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  font-size: 138px;
  line-height: 218px;
  font-family: 'bth';
  z-index: 99;
  background: black;
}


.progress-bar {
  width: 380px;
  height: 380px;
  border-radius: 50%;
  background: conic-gradient(rgb(35, 255, 0) var(--progress), transparent var(--progress));
  transform: scaleX(-1);
}

.countdown {
  text-align: center;
  font-size: 24px;
}

.left_bottom {
  width: 442px;
  height: 216px;
  border: 8px solid #FFFFFF;
  border-radius: 70px;
  margin-left: 116px;
  margin-top: 50px;
  font-family: 'bth';
}

.left_bottom_title {
  text-align: center;
  color: #FFFFFF;
  font-size: 69px;
  margin-top: 25px;
  height: 69px;
}

.left_bottom_num {
  text-align: center;
  color: #FFFFFF;
  font-size: 90px;
}
</style>
